import { Subscription } from '@wix/ambassador-billing-v1-subscription/types';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Button, ButtonPriority, ButtonSize, SectionNotification } from 'wix-ui-tpa';
import { connect } from '../../../../common/components/runtime-context';
import { IActionCreators } from '../../../../types/internal-types';
import { RootState } from '../../state';
import { classes } from './SubscriptionsListItemBass.st.css';
import { aClickPayNowCtaEvent } from '@wix/bi-logger-my-subscriptions/v2';
import { formatDate } from '../../../../utils/displayUtils';
import { getRegionalSettings } from '../../selectors';
import Icons from 'wix-ui-icons-common/on-stage/Error';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;

interface Props {
  subscription: Subscription;
}

const PayNowSectionHelper = ({ subscription, actionCreators, regionalSettings }: Props & RuntimeProps) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const isInRecurringRetries = subscription?.billingStatus?.gracePeriodData?.automaticRetryData?.enabled === true;

  const getPayNowSectionHelperText = () => {
    return (
      <>
        {isInRecurringRetries
          ? t('app.settings.notification-pay-now.active.recurring_retries', {
              gracePeriodEndDate: formatDate(subscription.billingStatus?.gracePeriodData?.endDate, regionalSettings),
              interpolation: { escapeValue: false },
            })
          : t('app.setting.notification-pay-now.info', {
              lastPaymentDate: formatDate(subscription.billingStatus?.gracePeriodData?.startDate, regionalSettings),
              gracePeriodEndDate: formatDate(subscription.billingStatus?.gracePeriodData?.endDate, regionalSettings),
              interpolation: { escapeValue: false },
            })}
      </>
    );
  };
  const biLogger = useBi();
  const onCtaClick = () => {
    biLogger.report(
      aClickPayNowCtaEvent({
        cycle: subscription.billingStatus?.currentCycle,
        cycleAutoRenew: subscription.billingSettings?.cycleAutoRenew,
        nextBillingDate: subscription.billingStatus?.nextBillingDate?.getTime(),
        paymentMethodId: subscription.billingSettings?.paymentMethod?.id,
        previousBillingDate: subscription.billingStatus?.previousBillingDate?.getTime(),
        subscriptionId: subscription.id!,
        subscriptionStatus: subscription.status,
      }),
    );
    actionCreators.openPayNowModal(subscription.id!);
  };

  const CtaButton = () => (
    <div>
      <Button
        data-hook="pay-now-cta-button"
        size={ButtonSize.tiny}
        priority={ButtonPriority.basicSecondary}
        onClick={onCtaClick}
        fullWidth={isMobile}
      >
        {t('app.settings.default.submit-payment.button')}
      </Button>
    </div>
  );

  return (
    <SectionNotification type="error" data-hook="pay-now-section-helper">
      {isMobile ? (
        <div>
          <div className={classes.payNowSectionHelper}>
            <SectionNotification.Text>{getPayNowSectionHelperText()}</SectionNotification.Text>
            <CtaButton />
          </div>
        </div>
      ) : (
        <div className={classes.payNowSectionHelper}>
          <div className={classes.payNowSectionText}>
            <SectionNotification.Icon icon={<Icons />} />
            <SectionNotification.Text>{getPayNowSectionHelperText()}</SectionNotification.Text>
          </div>
          <CtaButton />
        </div>
      )}
    </SectionNotification>
  );
};

const mapRuntimeToProps = (state: RootState, _props: Props, actionCreators: IActionCreators) => {
  return {
    actionCreators,
    regionalSettings: getRegionalSettings(state),
  };
};

export default connect(mapRuntimeToProps)(PayNowSectionHelper);
